<template>
<div>
    <h2>Solution</h2>
    <p>
        Le 12 juillet 2024, lors de la soirée d’inauguration de son nouveau mandat au Grand Hastur Hotel d’Amsterdam, 
        Jasper Van der Meer meurt empoisonné après avoir prononcé son discours et porté un toast. Le meurtre est 
        orchestré par son épouse, Lucy Van der Meer, avec l’aide de Koen, responsable du room service et du parking. 
        Soumis à la volonté de Lucy et espérant une promotion (<i>Témoignage de Sophie Beaumont</i>), Koen suit 
        scrupuleusement ses ordres. De son côté, Lucy vit sous l’emprise d’un mari violent. Sa blessure à l’œil, 
        maquillée par Eliza, la journaliste venue l’interviewer pour un reportage sur “la femme la plus puissante 
        d’Amsterdam”, témoigne des abus physiques de Jasper (<i>Témoignage d’Eliza Janssen, Article de Gerald 
        Peters</i>). Mais plus que les violences, c’est la peur d’être démasquée qui pousse Lucy à agir. Elle sait 
        que Jasper finira par découvrir qu’elle est à l’origine des fuites qui compromettent sa carrière (<i>Téléphone 
        de Lucy</i>).
    </p>
    <p>
        Le journaliste Gerald Peters, un ami proche de Lucy, s’apprête à publier son témoignage anonyme dans un 
        article exposant les abus et malversations du maire. Cependant, avant la publication complète, un court 
        article annonçant les fuites alerte Jasper (<i>Article de Gerald Peters</i>). Furieux et décidé à sauver 
        sa réputation, il fait kidnapper Gerald et le séquestre dans la ferme familiale, héritée de sa mère, pour 
        empêcher toute révélation supplémentaire. Jasper prévoit d’envoyer son frère Dirk à la ferme pour éliminer 
        Gerald, ce qui compromettrait Lucy et mettrait fin à son plan (<i>Enregistrement audio, Téléphone de Lucy, 
        Acte de propriété</i>). Sentant que le temps presse, Lucy décide de tuer Jasper avant qu’il ne découvre 
        toute la vérité et se retourne contre elle.
    </p>
    <p>
        Le plan consistait à piéger la suite de Dirk en y plaçant des gaufres empoisonnées et des extraits de poison 
        sur le lit (<i>Photo de la chambre aux roses, Témoignage de Maria 19h30</i>). Jasper, amateur de White 
        Russian (<i>Carte des cocktails, photo du verre du maire, Tweet de Lucy</i>), doit boire ce cocktail au cours 
        de la soirée. Une fois le poison combiné avec la caféine du cocktail, il devient mortel (<i>Analyse 
        toxicologique</i>). Les preuves placées dans la suite de Dirk doivent faire croire que ce dernier est 
        responsable de l’empoisonnement. Lucy a soigneusement attisé la rivalité entre les deux frères, notamment 
        autour de l’héritage de la ferme familiale et des dettes de Dirk envers Jasper. Elle a aussi semé le doute 
        par des échanges de SMS, insinuant que Dirk pourrait trahir son frère (<i>Téléphone de Lucy</i>).
    </p>
    <p>
        Mais le plan déraille lorsque Dirk présente à Jasper une page arrachée du livre d’or de l’hôtel, prouvant 
        que Lucy connaît bien Gerald Peters (<i>Page du livre d’or, Témoignage de Joris Boor</i>). Furieux, Jasper 
        décide d’envoyer Dirk à la ferme pour tuer Gerald, forçant Dirk à annuler sa réservation à l’hôtel, ce qui 
        risque de faire échouer la mise en scène. (<i>Téléphone de Lucy, Témoignage de Dirk, Témoignage de Maria 
        21h15, Tableau check-in/room service</i>), Lucy, paniquée, ordonne alors à Koen de nettoyer la suite de 
        Dirk et de déplacer les preuves dans le coffre de sa voiture, en lui transmettant ses ordres par le biais 
        d’un message inscrit sur le petit parasol de son cocktail (<i>Vidéosurveillance de la réception, Photo du 
        parasol</i>). Koen obéit : il place les extraits de poison et des miettes de gaufres dans le véhicule de 
        Dirk sur le parking, abandonnant son poste à la réception (<i>Photo du coffre de voiture, Témoignage de 
        Maria 21h05</i>).
    </p>
    <p>
        Après sa visite dans la suite de Jasper, Dirk se rend dans la chambre de leur mère à l’hôtel. De là, il 
        prend des photos compromettantes de Katarina, la cheffe du parti des Incorruptibles, et d’un serveur de 
        l’hôtel (<i>Témoignage de Maria, 21h15</i>). Ces clichés doivent lui permettre de faire pression sur 
        Katarina, qui menace de révéler les scandales de corruption des frères Van der Meer. Le micro oublié par 
        la journaliste Lotte Janssen enregistre une conversation entre Dirk et Katarina. Lors de cet échange, 
        Katarina confirme qu’elle est au courant des crimes des deux frères et qu’elle est prête à tout révéler 
        (<i>Enregistrement audio</i>).
    </p>
    <p>
        Sophie, employée du room service, livre bien les gaufres empoisonnées, mais elle ignore leur contenu. Koen 
        avait discrètement placé la boîte piégée sur son chariot (<i>Témoignage de Sophie</i>). Il lui demande aussi 
        de nettoyer la suite aux roses, initialement réservée à Dirk, mais elle n’a pas le temps de s’en occuper. Des
        clients mécontents interrompent son travail pour se plaindre de l’absence de réponse aux appels à la 
        réception, car Koen est occupé à piéger la voiture de Dirk (<i>Téléphone de Sophie, Témoignage de Sophie, 
        Tableau check-in/room service</i>).
    </p>
    <p>
        Le plan de Lucy aurait pu réussir, mais le témoignage de la mère de Jasper et Dirk, qui observe toute la 
        soirée depuis la fenêtre de sa chambre, fait apparaître des incohérences. Elle rapporte avoir vu Koen entrer 
        dans la suite aux roses avec une boîte de gaufres avant la réception (<i>Témoignage de Maria 19h30</i>) et 
        le surprend plus tard en train de manipuler le coffre de la voiture de Dirk (<i>Témoignage de Maria 
        21h05</i>). Ces éléments, croisés avec les autres témoignages et indices, permettent aux enquêteurs de 
        comprendre que la mort de Jasper Van der Meer n’est pas accidentelle mais le résultat d’un complot 
        orchestré par Lucy, avec l’aide de Koen.
    </p>
</div>
</template>

<script>
export default {
  name: "JasperVanDerMeer",
}
</script>

<style scoped>
body .theme--light.v-application {
    color: #fff;
    padding: 30px;
    text-align: center;
}
</style>